@use '../node_modules/@angular/material' as mat;
@use "./styles/style-variables" as var;

@import '../node_modules/quill/dist/quill.snow.css';

@import "../node_modules/material-design-icons-iconfont/dist/material-design-icons.css";

@import "../node_modules/@fortawesome/fontawesome-free/css/brands.min.css";
@import "../node_modules/@fortawesome/fontawesome-free/css/solid.min.css";
@import "../node_modules/@fortawesome/fontawesome-free/css/fontawesome.min.css";

@import "styles/fonts";

@import "./styles/style-reset";
@import "./styles/cf";

//@import "./styles/light-theme";
@import "./styles/dynamic-theme";
@import "./styles/typography";

@import "styles/custom/tab-bar";
@import "styles/custom/button";
@import "styles/custom/chip";
@import "styles/custom/card";
@import "styles/custom/stepper";
@import "styles/custom/form-field";
@import "styles/custom/slide-toggle";
@import "styles/custom/expansion-panel";
@import "styles/custom/folder.style";

@import "styles/margin";
@import "styles/size";
@import "styles/text";
@import "styles/border";

@include mat.core($recrewt-typography);

@mixin custom-components-theme() {
  @include recrewt-tab-bar-style();
  @include recrewt-button-style();
  @include recrewt-chip-style();
  @include recrewt-card-style();
  @include recrewt-stepper-style();
  @include recrewt-form-field-style();
  @include recrewt-slide-toggle-style();
  @include recrewt-expansion-panel-style();
}

.recrewt-light-theme {
  @include mat.all-component-themes($dynamic-app-theme);
  @include custom-components-theme();
}

.inline {
  display: inline-block;
}

.primary-back {
  background-color: var.$primary;
  color: var.$on-primary;
}

.primary-back-medium {
  background-color: var.$primary-300;
  color: var.$on-primary;
}

.primary-back-low {
  background-color: var.$primary-100;
  color: var.$text;
}

.primary {
  color: var.$primary;
}

.accent {
  color: var.$accent;
}

.default-accent-bg {
  background-color: var.$defaultAccent !important;
  color: white;
}

.default-primary {
  color: var.$defaultPrimary !important;
}

.accent-medium-bg {
  background-color: #ebeef1;
}

.light-bg {
  background-color: #F6F8FA;
}

.accent-medium-border {
  border: #ebeef1 1px solid;
}

.accent-bg {
  background-color: var.$accent;
  color: var.$on-accent;
}

.warn-bg {
  background-color: var.$warn;
  color: var.$on-accent;
}

.gradient {
  background-image: linear-gradient(to bottom right, var.$defaultPrimary, var.$defaultTertiary);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.gradient-bg {
  background-image: linear-gradient(to bottom right, var.$defaultPrimary, var.$defaultTertiary);
  color: white;
}

.gradient-border {
  background:
          linear-gradient(white, white) padding-box,
          linear-gradient(to bottom right, var.$defaultPrimary, var.$defaultTertiary) border-box;
  border: 6px solid transparent;
}

a {
  text-decoration: none;
  color: var.$accent;

  &:hover {
    color: var.$primary;
  }
}

.surface {
  background-color: white;
}

.clickable {
  cursor: pointer;

  &:active {
    @include mat.elevation(7);
  }

  transition: box-shadow 0.15s ease-in-out;
}

.ellipsize {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.no-select {
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.on-accent {
  color: var.$on-accent;
}

.on-primary {
  color: var.$on-primary;
}

.on-primary-medium {
  color: var.$on-primary;
  opacity: 0.74;
}

.on-primary-low {
  color: var.$on-primary;
  opacity: 0.38;
}

.primary-light {
  background-color: var.$primary-100;
}

.primary-medium {
  color: var.$primary-300;
}

.decoration {
  top: 0;
  position: absolute;
  width: 60vw;
  min-width: 500px;
}

.position-top-left {
  transform: translateX(-40%) translateY(-68%);
}

.position-top-right {
  transform: translateX(40%) translateY(-68%);
}

.tint-white {
  filter: brightness(0) invert(1);
}

.tint-gray {
  filter: brightness(0.5);
}

.on-surface-medium {
  color: var.$secondary-text;
}

.warn {
  color: var.$warn;
}

.on-surface {
  color: var.$text;
}

.tooltip {
  background-color: rgba(0, 0, 0, 0.54);
}

.hover-effect {
  &:hover, &:focus {
    cursor: pointer;
    background-color: rgba(0, 0, 0, 0.04);
    transition: background-color 0.2s ease-in-out;

    &.gray {
      background-color: rgb(243, 243, 243);
    }
  }
}

p.single-line {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 1; /* number of lines to show */
  -webkit-box-orient: vertical;
}

snack-bar-container.success {
  background: green !important;
  color: white !important;

  .mat-simple-snackbar-action {
    color: white !important;
  }
}

snack-bar-container.error-msg {
  background: var.$warn !important;
  color: white !important;

  .mat-simple-snackbar-action {
    color: white !important;
  }
}

snack-bar-container.info {
  background: var.$primary !important;
  color: white !important;

  .mat-simple-snackbar-action {
    color: white !important;
  }
}

* {
  transition: background-color 0.2s ease-in-out;
}

.pointer {
  &:hover {
    cursor: pointer;
  }
}

.recrewt-card {
  border: 1px solid #dee2e6;
}

.mat-loading .mat-button-wrapper {
  visibility: hidden;
}

.mat-loading .mat-progress-spinner {
  position: absolute;
  top: calc(50% - 10px);
  left: calc(50% - 10px);
}

.err-box {
  @extend .b-rad-1;
  @extend .p-1;
  background-color: var.$warn-100;
}

.warn-box {
  @extend .b-rad-1;
  @extend .p-1;
  background-color: var.$primary-100;
}

.hint-box {
  @extend .b-rad-1;
  @extend .p-1;
  background-color: #CFF5F6;

  .mat-icon {
    color: #0055BC;
  }
}

.info-box {
  border-left: var.$primary solid 2px;
  padding-left: 16px;
}

.no-pad .mat-dialog-container.mat-dialog-container {
  padding: 0 !important;
  border-radius: 16px;
  overflow: hidden;
}

.full-screen {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  overflow-y: scroll;
  background-color: white;
  z-index: 100;
}

.divider {
  background-color: #ebeef1;
  width: 1px;
  height: 16px;
  margin-left: 8px;
  margin-right: 20px;
  z-index: 100;
}

.mat-progress-spinner {
  stroke-linecap: round;
}

.light-bottom-border {
  box-shadow: inset 0 -1px #ebeef1;
}

.light-top-border {
  box-shadow: inset 0 1px #ebeef1;
}

.keep-linebreaks {
  white-space: pre-wrap;
}

.hidden {
  visibility: hidden;
  display: none;
}

.center {
  display: flex;
  flex: 1;
  justify-content: center;
  border-radius: 2px;
}

.pull-right {
  float: right;
}
