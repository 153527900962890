@use "style-variables" as var;

html,
body {
  height: 100vh;
  width: 100vw;
}

body {
  margin: 0;
  padding: 0;
}

.mat-body-1,
.mat-display-3 {
  margin: 0;
  padding: 0;
}

*,
*:focus,
*:hover {
  outline: none;
}

div * {
  ::-webkit-scrollbar-track {
    background-color: transparent;
  }

  ::-webkit-scrollbar {
    width: 6px;
    height: 6px;
    background-color: transparent;
  }

  ::-webkit-scrollbar-thumb {
    background-color: transparent;
  }

  &::-webkit-scrollbar-track {
    background: white;
    border-radius: 16px;
  }

  &::-webkit-scrollbar-thumb {
    background: #bfbfbf;
    border-radius: 16px;
    opacity: 0.6;
  }

  &::-webkit-scrollbar-thumb:hover {
    transition: background 1s ease-in-out;
    background: #969696;
  }
}

.mat-menu-panel, .mat-select-panel {
  max-width: none !important;
}

.mat-checkbox-layout { white-space: normal !important; }

mat-list-option.auto {
  height: auto !important;
  min-height: 48px !important;
  display: flex !important;

  .mat-list-item-content {
    width: 100% !important;
  }
}
